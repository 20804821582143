<template>
    
</template>

<script>
    export default {
        name: "RegistrationOverview"
    };
</script>

<style scoped>

</style>
